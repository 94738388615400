"use client";

import { CurrencyFormattingRules } from "@/lib/5874/types";
import Cookies from "js-cookie";
import { createContext, useContext } from "react";

interface State {
	region: string;
	storeInfo: {
		hash: string;
		channel: number;
	};
	currencyFormattingRules: CurrencyFormattingRules;
}

interface ProviderProps {
	region: string;
	storeInfo: {
		hash: string;
		channel: number;
	};
	currencyFormattingRules: CurrencyFormattingRules;
	children?: React.ReactNode;
}

export const BigCommerceStoreContext = createContext({} as State);

export const BigCommerceStoreContextProvider = ({
	region,
	storeInfo,
	currencyFormattingRules,
	children,
}: ProviderProps) => {
	const value = {
		region,
		storeInfo,
		currencyFormattingRules,
	};

	return (
		<BigCommerceStoreContext.Provider value={value}>
			{children}
		</BigCommerceStoreContext.Provider>
	);
};

export const useBigCommerceStoreContext = () => {
	const context = useContext(BigCommerceStoreContext);

	if (!context) {
		throw new Error(
			"useBigCommerceStoreContext must be used within a BigCommerceStoreContextProvider",
		);
	}

	return context;
};
